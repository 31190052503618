import { useFetcher } from "@remix-run/react";
import { Button } from "./button.component";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";

export default function ImpersonationBanner({
  user,
}: {
  user?: {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
  } | null;
}) {
  const fetcher = useFetcher();

  if (!user) {
    return null;
  }

  return (
    <div className="fixed z-[51] bottom-4 left-1/2 transform -translate-x-1/2">
      <div className="bg-red-500 py-2 px-4 shadow-md flex items-center justify-center gap-x-5 ring-2 ring-red-500 text-white rounded-md">
        <ExclamationCircleIcon className="h-6 w-6 text-white" />
        <div className="w-auto flex-shrink-0 text-sm">
          You are impersonating {user.first_name} {user.last_name}
          <br />(<b className="font-semibold">{user.email}</b>)
        </div>
        <Button
          color="transparent"
          className="flex-shrink-0 bg-white hover:bg-gray-50 hover:text-red-600 text-red-500 text-sm"
          onClick={() =>
            fetcher.submit(
              { intent: "un-impersonate" },
              { method: "post", action: `/admin/users/${user.id}/impersonate` }
            )
          }
        >
          Un-impersonate
        </Button>
      </div>
    </div>
  );
}
